import styled from "styled-components"

import { Display as BaseDisplay } from "../UI"

export const Wrapper = styled.section`
  width: 100vw;
  @media (min-width: 660px) {
    height: 500px;
  }
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;

  padding: 120px 40px 120px 40px;

  @media (max-width: 550px) {
    padding: 48px 20px 64px 20px;
  }
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 640px;

  display: flex;
  flex-direction: column;
  transform: translate3d(0,0,0);
`

export const Display = styled(BaseDisplay)`
  margin-bottom: 20px;

  @media (max-width: 550px) {
    margin-bottom: 12px;
  }
`
