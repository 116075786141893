// @flow

// Standard:
// "\u2588",
// "\u2593",
// "\u2592",
// "\u2591",
// "\u2588",
// "\u2593",
// "\u2592",
// "\u2591",
// "\u2588",
// "\u2593",
// "\u2592",
// "\u2591"
// "\u003c"
// "\u003e",
// "\u002f"

import baffle from "baffle"

export default (node, config) => {
  if (node) {
    return baffle(node, {
      speed: 80,
      characters: ["\u2588"],
      // characters: ["\u2588"],
      // characters: ["█", "▓", "▒", "░", "█", "▓", "▒", "░", "█", "▓", "▒", "░", "<", ">", "/"],
      ...config
    })
  }
}
