import styled, { css } from "styled-components"

import { Link as GatsbyLink } from "gatsby"
import { Text as BaseText, Label as WhiteLabel } from "../UI"

import rightArrow from "../../images/right-arrow.svg"
import rightArrowWhite from "../../images/right-arrow-white.svg"

export const Wrapper = styled.section`
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;

  display: flex;
  justify-content: space-between;

  padding: 40px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  @media (max-width: 550px) {
    padding: 40px 20px 40px 20px;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  width: 50%;

  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media (max-width: 550px) {
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  ${({ black }) =>
    black &&
    css`
      background-color: #000;
      padding: 80px;
      padding-right: 0px;

      @media (max-width: 1000px) {
        width: 100vw;
        margin-left: -40px;

        padding: 64px 40px;
      }

      @media (max-width: 550px) {
        width: 100vw;
        margin-left: -20px;

        padding: 56px 20px;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;

        top: 0;
        right: -100vw;

        width: 100vw;
        height: 100%;

        background-color: #000;

        @media (max-width: 1000px) {
          display: none;
        }
      }
    `}
`

export const Content = styled.div`
  width: 100%;
  max-width: 290px;
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media (max-width: 1000px) {
    width: calc(50% - 32px);
    max-width: none;
  }

  @media (max-width: 550px) {
    width: 100%;
    margin-bottom: 32px;
  }
`

const getArrow = ({ white }) => {
  return white ? rightArrowWhite : rightArrow
}

export const Link = styled(GatsbyLink)`
  position: relative;
  display: inline-block;

  padding-right: 24px;

  &:after {
    content: "";
    display: block;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    right: 0px;

    height: 12px;
    width: 12px;

    transition: right 0.5s cubic-bezier(0.2, 1, 0.2, 1);

    background-image: url(${getArrow});

    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  &:hover:after {
    right: -8px;
  }
`

export const Text = styled(BaseText)`
  margin-top: 4px;

  @media (max-width: 1000px) {
    width: 100%;
    max-width: 300px;
  }
`

export const Label = styled(WhiteLabel)`
  margin-bottom: 40px;
`
