// @flow

import React, { Component } from "react"
import * as S from "./style"
import { Heading } from "../UI"

import useBaffle from '../../utils/useBaffle'

import { FormattedMessage } from 'react-intl'

const Hero = () => {
  const displayRef = React.useRef()
  const headingRef = React.useRef()

  useBaffle([displayRef, headingRef])

  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Display ref={displayRef}>
            <FormattedMessage id="homepage.hero.title" />
          </S.Display>
          <Heading ref={headingRef}>
            <FormattedMessage id="homepage.hero.text" />
          </Heading>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  )
}

export default Hero
