import React from "react"
import Layout from "../components/Layout/Layout"
import Hero from "../components/Hero/Hero"
import Services from "../components/Services/Services"

const IndexPage = ({ pageContext: { locale } }) => {
  const metadata = {
    title: "homepage.metadata.title",
    description: "homepage.metadata.description",
  }

  return (
    <Layout locale={locale} metadata={metadata}>
      <Hero />
      <Services />
    </Layout>
  )
}

export default IndexPage
