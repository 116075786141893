import React from 'react'

import baffle from './baffle'

export default (refs, revealTime = 800, timeoutTime = 500) => {
  const timeoutId = React.useRef()

  React.useEffect(() => {
    const baffleInstances = []

    refs.forEach(ref => {
      if (ref.current) {
        const instance = baffle(ref.current)
        instance.start()
        baffleInstances.push(instance)
      }
    })

    timeoutId.current = setTimeout(() => {
      baffleInstances.forEach(instance => {
        instance.reveal(revealTime)
      })
    }, timeoutTime)

    return function cleanup() {
      clearTimeout(timeoutId.current)
    }
  }, [])
}
