import React from "react"
import PropTypes from "prop-types"

import { Title } from "../UI"

import * as S from "./style"

import { FormattedMessage } from 'react-intl'

const ServiceItem = ({ title, children, url, white }) => {
  return (
    <S.Content>
      <S.Link to={url} white={white}>
        <Title white={white}>{title}</Title>
      </S.Link>
      <S.Text white={white}>{children}</S.Text>
    </S.Content>
  )
}

ServiceItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  white: PropTypes.bool,
}

const Services = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Column>
          <ServiceItem url="/services" title={<FormattedMessage id="homepage.services.pentest.title" />}>
            <FormattedMessage id="homepage.services.pentest.description" />
          </ServiceItem>
          <ServiceItem url="/services" title={<FormattedMessage id="homepage.services.redTeaming.title" />}>
            <FormattedMessage id="homepage.services.redTeaming.description" />
          </ServiceItem>
          <ServiceItem url="/services" title={<FormattedMessage id="homepage.services.blueTeaming.title" />}>
            <FormattedMessage id="homepage.services.blueTeaming.description" />
          </ServiceItem>
          <ServiceItem url="/services" title={<FormattedMessage id="homepage.services.consulting.title" />}>
            <FormattedMessage id="homepage.services.consulting.description" />
          </ServiceItem>
        </S.Column>
        <S.Column black>
          <S.Label>
            <FormattedMessage id="homepage.approach.label" />
          </S.Label>
          <ServiceItem url="/services" title={<FormattedMessage id="homepage.approach.assessment.title" />} white>
            <FormattedMessage id="homepage.approach.assessment.description" />
          </ServiceItem>
          <ServiceItem url="/services" title={<FormattedMessage id="homepage.approach.detectionAndResponse.title" />} white>
            <FormattedMessage id="homepage.approach.detectionAndResponse.description" />
          </ServiceItem>
          <ServiceItem url="/services" title={<FormattedMessage id="homepage.approach.compliance.title" />} white>
            <FormattedMessage id="homepage.approach.compliance.description" />
          </ServiceItem>
        </S.Column>
      </S.Container>
    </S.Wrapper>
  )
}

export default Services
